import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { PageProps, graphql } from "gatsby"
import PathContext from "../contexts/path.context"
import Layout from "../layouts/Primary/layout"
import HeroMazeDefault from "../components/hero-default"

import WidgetCulture from "../components/widgets/culture"
import JobListingComponent from "../components/job-listing"
import LinkedinNoscript from "./noscript/noscript-linkedin"
import FacebookNoscript from "./noscript/noscript-facebook"
import GTMNoscript from "./noscript/noscript-gtm"

export interface IJobPosting {}

export interface ISavedJobsListing {
  savedJobs: any
}

interface ISavedJobsContext {
  id: string
  slug: string
  locale: string
}

const SavedJobsTemplate: React.FC<PageProps<ISavedJobsListing, ISavedJobsContext>> = ({
  pageContext,
  path,
  data,
  savedJobs,
}) => {
  const locale = pageContext.locale || "en-US"
  const seoData = {
    title: "Saved jobs listing",
  }
  const [jobs, updateJobs] = useState(Object.values(savedJobs))

  useEffect(() => updateJobs(Object.values(savedJobs)), [savedJobs])
  return (
    <PathContext.Provider
      value={{
        current: path,
        slug: pageContext.slug,
        locale: locale,
        activeLanguage: locale.substr(0, 2),
      }}
    >
      <Layout overflowEnable exploreDisable seoData={seoData}>
        <HeroMazeDefault
          heading="My saved jobs"
          content="Here you'll find roles you’ve explored and saved for later."
          className="jobs-hero"
        />
        <section className="jobs-container saved-jobs-container">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2 main-col">
                <div className="grid job-listing">
                  <JobListingComponent leverJobs={jobs} showPagination className='saved-jobs'/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <WidgetCulture />
      </Layout>

      {/* Linkedin, Facebook and GTM noscript */}
      <LinkedinNoscript />
      <FacebookNoscript />
      <GTMNoscript />
    </PathContext.Provider>
  )
}

export default connect(
  state => ({
    savedJobs: state.app.savedJobs,
  }),
  null
)(SavedJobsTemplate)

export const query = graphql`
  query {
    allLever {
      nodes {
        ...JobPosting
      }
    }
  }
`
